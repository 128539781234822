import { ButtonOutlined } from "@components/Reusables";
import { ButtonPrimary, chartOptions, defaultDataPrimary } from "@components";
import React, { Dispatch, SetStateAction, useState, useCallback } from "react";
import { useStyles } from "./mainSection2Mobile.style";
import { Line } from "react-chartjs-2";
import {
  sinApv,
  regimenA,
  regimenB,
  regimenMixto,
  estrella,
  eye,
} from "@images";

type Props = {
  setPage: Dispatch<SetStateAction<number>>;
};

const ApvContainer2Mobile: React.FC<Props> = ({ setPage }) => {
  const classes = useStyles();
  const [prevData, setPrevData] = useState({
    afp: "Plan Vital",
    salary: 1000000,
    amount: 50000,
    age: 22,
  });
  const [style, setStyle] = useState("resultContainer");
  const [focused, setFocused] = useState(3);
  const [graphData, setGraphData] = useState(defaultDataPrimary);

  const [results, setResults] = useState([
    {
      id: 0,
      name: "Sin APV",
      description: "Sin ningún régimen",
      amount: 0,
      icon: sinApv,
      star: false,
      text: "Si sacas tu plata el Estado te penaliza quitándote las bonificaciones y... Sin APV",
    },
    {
      id: 1,
      name: "Régimen A",
      description: "Sin ningún régimen",
      amount: 25000,
      icon: regimenA,
      star: false,
      text: "Si sacas tu plata el Estado te penaliza quitándote las bonificaciones y... Regimen A",
    },
    {
      id: 2,
      name: "Régimen B",
      description: "Sin ningún régimen",
      amount: 25000,
      icon: regimenB,
      star: true,
      text: "Si sacas tu plata el Estado te penaliza quitándote las bonificaciones y... Regimen B",
    },
    {
      id: 3,
      name: "Mixto",
      description: "Sin ningún régimen",
      amount: 15000,
      icon: regimenMixto,
      star: false,
      text: "Si sacas tu plata el Estado te penaliza quitándote las bonificaciones y... Regimen Mixto",
    },
  ]);
  // usar UseCallback
  const changeStyle = useCallback(
    (index: number) => {
      setFocused(index);
    },
    [focused]
  );

  return (
    <div className={classes.container}>
      <div className={classes.topSection}>
        <div className="prevData">
          <div>
            <span className="recapTitle">Donde</span>
            <span className="recapData">{prevData.afp}</span>
          </div>
          <div>
            <span className="recapTitle">Sueldo bruto</span>
            <span className="recapData">
              $ {prevData.salary.toLocaleString("es")}
            </span>
          </div>
          <div>
            <span className="recapTitle">Monto mensual</span>
            <span className="recapData">
              $ {prevData.amount.toLocaleString("es")}
            </span>
          </div>
          <div>
            <span className="recapTitle">Edad</span>
            <span className="recapData">{prevData.age}</span>
          </div>
        </div>
        <div className={classes.apvOptions}>
          {results.map((result, index) => (
            <>
              {focused === result.id ? (
                <div
                  className="apvElement focus"
                  onClick={() => changeStyle(index)}
                >
                  <div className="whiteBox iconContainerFocused">
                    <img src={result.icon} />
                  </div>
                  <div className="whiteBox infoContainerFocused">
                    <div className="texts">
                      <span className="spanStar">
                        {result.name}
                        {result.star && <img src={estrella} />}
                      </span>
                      <br />
                      <span className="spanDescription">
                        {result.description}
                      </span>
                    </div>
                    <span className="amountFocus">
                      $ {result.amount.toLocaleString("es")}
                    </span>
                  </div>
                </div>
              ) : (
                <div className="apvElement" onClick={() => changeStyle(index)}>
                  <div className="whiteBox iconContainer">
                    <img src={result.icon} />
                  </div>
                  <div className="whiteBox infoContainer">
                    <span className="unFocusName">
                      <span>{result.name}</span>
                      {result.star && <img src={estrella} />}
                    </span>
                    <span className="amount">
                      $ {result.amount.toLocaleString("es")}
                    </span>
                  </div>
                </div>
              )}
            </>
          ))}
        </div>
      </div>
      <div className={classes.midSection}>
        <div className="optionData">
          <img src={eye} />
          <span className="question">¿En que me fijo?</span>
          <span className="dataMainText">{results[focused].text}</span>
        </div>
        <div className="chartContainer">
          <Line options={chartOptions} data={graphData} />
        </div>
        <div className={classes.bottomSection}>
          <ButtonPrimary
            className="btn"
            text="Guardar y volver a Mis Finanzas"
            onClick={() => {
              setPage(1);
            }}
          />
          <ButtonOutlined
            className="btn"
            text="Ir atras y editar"
            onClick={() => {
              setPage(1);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ApvContainer2Mobile;
