import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#edebeb",
  },
  topSection: {
    background: "linear-gradient(92deg, #4619b6 8%, #8352fd 100%)",
    marginTop: "-2rem",
    paddingTop: "2rem",
    height: "21.875rem",
    marginBottom: "9rem",
    borderRadius: "0 0 1.875rem 1.875rem",
    "& .prevData": {
      height: "4rem",
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      justifyContent: "center",
      overflowX: "auto",
      [theme.breakpoints.down(600)]: {
        justifyContent: "flex-start",
      },
      "& div": {
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        width: "10rem",
        minWidth: "7.188rem",
        margin: "0 1rem",
        "& span": {
          minWidth: "7.188rem",
          color: "white",
          fontFamily: "Montserrat",
          textAlign: "left",
        },
        "& .recapTitle": {
          mixBlendMode: "overlay",
          fontSize: 12,
          fontWeight: 500,
          lineHeight: 3.24,
        },
        "& .recapData": {
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 2.43,
          marginTop: "-1rem",
        },
      },
    },
  },
  apvOptions: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignItems: "center",
    "& .apvElement": {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      margin: "0.5rem",
      "& .whiteBox": {
        backgroundColor: "#ffffff",
        marginRight: "0.5rem",
        borderRadius: "1rem",
        boxShadow: "0.25rem 0.25rem 1.25rem 0.125rem rgba(0, 0, 0, 0.2)",
      },
      "& .infoContainer": {
        width: "17.25rem",
        height: "3.125rem",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "0.55rem 1.313rem 0.625rem 1rem",
        alignItems: "center",
        "& .unFocusName": {
          textAlign: "left",
          fontFamily: "Montserrat",
          fontSize: 14,
          fontWeight: "bold",
          lineHeight: 2.14,
          color: theme.palette.text.primary,
          display: "flex",
          flexDirection: "row",
          "& >img": {
            heigth: "1.25rem",
            width: "1.25rem",
          },
        },
        "& .amount": {
          textAlign: "left",
          fontFamily: "Montserrat",
          fontSize: 16,
          fontWeight: "normal",
          lineHeight: 2.14,
          color: theme.palette.text.primary,
        },
      },
      "& .iconContainer": {
        width: "3.125rem",
        height: "3.125rem",
        padding: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& >img": {
          // padding: "24px 16px 24px 15px",
        },
      },
    },
    "& .focus": {
      height: "9rem",
      padding: 0,
      order: 3,
      "& .infoContainerFocused": {
        height: "100%",
        width: "15rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        "& .texts": {
          padding: "0.313rem 1.313rem 0.625rem 2rem",
          "& .spanStar": {
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            justifyContent: "space-between",
            textAlign: "left",
            fontFamily: "Montserrat",
            fontSize: 14,
            fontWeight: "bold",
            lineHeight: 2.14,
            color: theme.palette.text.primary,
            "& >img": {
              heigth: "1.25rem",
              width: "1.25rem",
            },
          },
          "& .spanDescription": {
            textAlign: "left",
            fontFamily: "Montserrat",
            fontSize: 12,
            fontWeight: 400,
            lineHeight: 1.5,
            color: theme.palette.text.primary,
          },
        },
      },
      "& .iconContainerFocused": {
        width: "5rem",
        height: "5rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& >img": {
          // padding: "24px 16px 24px 15px",
          filter:
            "invert(33%) sepia(64%) saturate(4015%) hue-rotate(243deg) brightness(102%) contrast(98%)",
        },
      },
      "& .amountFocus": {
        backgroundColor: "#8352fd",
        height: "3.375rem",
        borderRadius: "1rem",
        padding: "0.75rem 7.5rem 0.75rem 2rem",
        fontSize: 20,
        fontFamily: "Montserrat",
        fontWeight: "bold",
        lineHeight: 1.5,
        color: "#ffffff",
      },
    },
  },
  midSection: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignItems: "center",
    "& .optionData": {
      textAlign: "center",
      backgroundColor: "#ffffff",
      width: "auto",
      margin: "0 1rem",
      height: "25.688rem",
      borderRadius: "1.875rem",
      padding: "2rem 2rem 4rem",
      display: "flex",
      flexDirection: "column",
      boxShadow: "0.25rem 0.25rem 1.25rem 0.125rem rgba(0,0,0,0.2)",

      "& >img": {
        width: "3.438rem",
        height: "3.438rem",
        margin: "0 auto 0.7rem",
      },
      "& .question": {
        fontFamily: "Montserrat",
        fontSize: 16,
        fontWeight: "bold",
        lineHeight: 1.5,
        textAlign: "center",
        color: "#393e44",
      },
      "& .dataMainText": {
        marginTop: "1.5rem",
        textAlign: "left",
        fontFamily: "Montserrat",
        fontSize: 12,
        fontWeight: 500,
        lineHeight: 2,
        color: theme.palette.text.secondary,
      },
    },
    "& .chartContainer": {
      margin: "3rem auto",
    },
  },
  bottomSection: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignItems: "stretch",
    "& .btn": {
      margin: "2rem 2rem",
    },
  },
}));
