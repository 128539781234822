import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#F4F3F3",
    [theme.breakpoints.down(1300)]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  formBox: {
    width: 501,
    margin: "4.313rem 7.563rem 6.25rem 12.25rem",
    padding: "41px 57px 43px 47px",
    borderRadius: 30,
    boxShadow: "4px 4px 20px 2px rgba(0,0,0,0.2)",
    backgroundColor: "#ffffff",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down(1300)]: {
      margin: "-3rem 0",
      width: "100%",
      alignItems: "center",
      borderRadius: "1.875rem",
    },
  },
  formItem: {
    width: 300,
    height: 120,
    [theme.breakpoints.down(1300)]: {
      height: 100,
    },
    "& .afpSelector": {
      width: 300,
    },
    "& .input": {
      boxShadow: "2px 2px 7px 0 rgba(0, 0, 0, 0.2)",
    },
  },
  rightSection: {
    "& img": {
      width: 340,
      height: 328,
      margin: "4.313rem 246px 49px 107px",
      objectFit: "contain",
      [theme.breakpoints.down(1300)]: {
        width: "9.125rem",
        height: "8.813rem",
        margin: "0 auto",
      },
    },
    "& .textContainer": {
      width: 469,
      height: 218,
      margin: "49px 208px 76px 16px",
      fontFamily: "Montserrat",
      fontSize: 16,
      fontWeight: "bold",
      lineHeight: 1.88,
      letterSpacing: "normal",
      textAlign: "left",
      color: theme.palette.text.secondary,
      [theme.breakpoints.down(1300)]: {
        width: "18.5rem",
        height: "2rem",
        margin: "1rem auto 0.6rem",
        fontSize: 12,
      },
    },
    [theme.breakpoints.down(1300)]: {
      order: 0,
    },
    "& .button": {
      textAlign: "right",
      marginRight: "12.5rem",
    },
  },
  buttonMobile: {
    margin: "3.688rem auto",
  },
}));
