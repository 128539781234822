import { AfpTypeSelector } from "@components/Inputs";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useStyles } from "./mainSection.styles";
import { useMediaQuery } from "@material-ui/core";
import { BirthdateInput, ButtonPrimary, NumericInput } from "@components";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { SaveIcon } from "images/APV";

type Props = {
  setPage: Dispatch<SetStateAction<number>>;
};

const ApvContainer: React.FC<Props> = ({ setPage }) => {
  const classes = useStyles();
  const [isBirthdate, setBirthdate] = useState<Date>();
  const [monthAmount, setMonthAmount] = useState<number>(0);
  const [salary, setSalary] = useState<number>(1000);
  const [isapre, setIsapre] = useState<number>(1000);

  const isMobile = useMediaQuery("(max-width: 1300px)");

  return (
    <div className={classes.container}>
      <div className={classes.formBox}>
        {isMobile && (
          <div className={classes.rightSection}>
            <div className="inconContainer">
              <img src={SaveIcon} />
            </div>
            <div className="textContainer">
              <span>Diferencia entre apv a y b</span>
            </div>
          </div>
        )}
        <div className={classes.formItem}>
          <span>Donde cotizo (AFP)</span>
          <br />
          <AfpTypeSelector
            className="afpSelector input"
            name="afp"
            label="afp"
            onChange={() => {}}
          />
        </div>
        <div className={classes.formItem}>
          <span>Monto mensual</span>
          <NumericInput
            name="month-amount"
            className="input"
            value={monthAmount}
            onChange={(e) => {
              setMonthAmount(e.target.value as number);
            }}
          />
        </div>
        <div className={classes.formItem}>
          <span>Sueldo bruto</span>
          <NumericInput
            name="gross-salary"
            className="input"
            value={monthAmount}
            onChange={() => {}}
          />
        </div>
        <div className={classes.formItem}>
          <span>Cuanto cuesta tu plan de Isapre (UF)</span>
          <NumericInput
            name="isapre"
            className="input"
            value={isapre}
            onChange={() => {}}
          />
        </div>
        <div className={classes.formItem}>
          <span>Fecha de nacimiento</span>
          <BirthdateInput
            label="Fecha de nacimiento"
            className="input"
            onChange={(e) => setBirthdate(e.target.value as unknown as Date)}
            value={isBirthdate}
          />
        </div>
      </div>
      {!isMobile && (
        <div className={classes.rightSection}>
          <div className="inconContainer">
            <img src={SaveIcon} />
          </div>
          <div className="textContainer">
            <span>Diferencia entre apv a y b</span>
          </div>
          <div className="button">
            <ButtonPrimary
              startIcon={<ArrowRightIcon />}
              className="continueButton"
              onClick={() => {
                setPage(2);
              }}
              text="Continuar"
            />
          </div>
        </div>
      )}
      {isMobile && (
        <div className={classes.buttonMobile}>
          <ButtonPrimary
            startIcon={<ArrowRightIcon />}
            className="continueButton"
            onClick={() => {
              setPage(2);
            }}
            text="Continuar"
          />
        </div>
      )}
    </div>
  );
};

export default ApvContainer;
