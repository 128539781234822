import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#F4F3F3",
    paddingLeft: "11.125rem",
    paddingTop: "5.375rem",
  },
  topSection: {
    display: "flex",
    flexDirection: "row",
    "& .infoRecap": {
      display: "flex",
      flexDirection: "column",
      "& div": {
        display: "flex",
        flexDirection: "column",
      },
      "& .recapTitle": {
        height: 39,
        fontFamily: "Montserrat",
        fontSize: 14,
        fontWeight: 500,
        lineHeight: 2.78,
        textAlign: "left",
        color: theme.palette.text.secondary,
      },
      "& .recapData": {
        height: 39,
        fontFamily: "Montserrat",
        fontSize: 20,
        fontWeight: 500,
        lineHeight: 1.95,
        textAlign: "left",
        color: theme.palette.text.primary,
      },
    },
  },
  apvOptions: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "4rem",
    "& .normal": {
      display: "flex",
      flexDirection: "column",
      width: 202.4,
      height: 232.4,
      margin: "0 48.6px 31.6px 30px",
      padding: "28px 50.4px 56.4px 49px",
      borderRadius: 33.4,
      boxShadow: "4.4px 4.4px 22.2px 2.2px rgba(0,0,0,0.2)",
      backgroundColor: "#ffffff",
      alignItems: "center",
      justifyContent: "space-evenly",
    },
    "& .mainFocus": {
      display: "flex",
      flexWrap: "nowrap",
      flexDirection: "column",
    },
    "& .starIcons": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      "& .starIcon": {
        margin: "0 0rem 0rem 10rem",
        order: 0,
        width: "2rem",
        height: "2rem",
      },
      "& .notMain": {
        marginLeft: "7rem",
      },
      "& .typeIcon": {
        margin: "0rem auto",
        width: "2.5rem",
        height: "2.5rem",
      },
    },
    "& .iconFocus": {
      filter:
        "invert(33%) sepia(64%) saturate(4015%) hue-rotate(243deg) brightness(102%) contrast(98%)",
    },
    "& .focus": {
      display: "flex",
      flexDirection: "column",
      width: "16.313rem",
      height: "14.58rem",
      margin: "0 auto",
      padding: "28px 50.4px 0px 49px",
      borderRadius: "2.088rem 2.088rem  0 0",
      boxShadow: "4.4px 4.4px 22.2px 2.2px rgba(0,0,0,0.2)",
      backgroundColor: "#ffffff",
      alignItems: "center",
      justifyContent: "space-evenly",
      fontSize: 16,
      fontWeight: "bold",
    },
    "& .amountFocus": {
      backgroundColor: "#8352fd",
      width: "16.313rem",
      height: "6.938rem",
      margin: "0 auto",
      padding: "2.5rem 2.5rem",
      borderRadius: "0 0 2.088rem 2.088rem",
      fontFamily: "Montserrat",
      fontSize: 32,
      fontWeight: "bold",
      lineHeight: 0.94,
      textAlign: "center",
      color: "#ffffff",
    },
  },
  midSection: {
    display: "flex",
    flexDirection: "row",
    marginTop: "4.3rem",
    "& .optionData": {
      textAlign: "center",
      backgroundColor: "#ffffff",
      width: "28.563rem",
      height: "25.688rem",
      borderRadius: "1.875rem",
      padding: "2rem 2rem 4rem",
      display: "flex",
      flexDirection: "column",
      boxShadow: "0.25rem 0.25rem 1.25rem 0.125rem rgba(0,0,0,0.2)",

      "& >img": {
        width: "3.438rem",
        height: "3.438rem",
        margin: "0 auto 1.5rem",
      },
      "& .question": {
        fontFamily: "Montserrat",
        fontSize: 20,
        fontWeight: "bold",
        lineHeight: 1.5,
        textAlign: "center",
        color: "#393e44",
      },
      "& .dataMainText": {
        marginTop: "2.5rem",
        textAlign: "left",
        fontFamily: "Montserrat",
        fontSize: 14,
        fontWeight: 300,
        lineHeight: 2.14,
        color: theme.palette.text.secondary,
      },
    },
    "& .chartContainer": {
      height: "19.619rem",
      width: "39.563rem",
      margin: "6.875rem 1rem 3.5rem 6.06rem",
    },
  },
  bottomSection: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: "4rem",
    "& .btn": {
      margin: "2rem 2rem",
    },
  },
}));
