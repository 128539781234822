import ApvContainer from "@components/Apv/mainSection";
import ApvContainer2 from "@components/Apv/mainSection2";
import ApvContainer2Mobile from "@components/Apv/mainSection2Mobile";
import React, { useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import { LayoutBudget } from "@components/Structural/Budgets/Layout/Layout";
import { navigate } from "gatsby";
import { Route } from "@interfaces";
import { useAuth } from "@hooks";

const ChooseApv: React.FC = () => {
  useAuth({ redirectIfNotLogged: true });

  const [page, setPage] = useState<number>(1);

  const isMobile = useMediaQuery("(max-width: 1280px)");

  return (
    <LayoutBudget
      title="Como elegir mi APV"
      // subtitle="subtitle"
      onTurnBackClick={() => navigate(Route.rokipedia)}
      // subtitleMobile="Paso 2/6: tus ingresos variables"
    >
      {isMobile ? (
        <>
          {page === 1 ? (
            <ApvContainer setPage={setPage} />
          ) : (
            <ApvContainer2Mobile setPage={setPage} />
          )}
        </>
      ) : (
        <>
          {page === 1 ? (
            <ApvContainer setPage={setPage} />
          ) : (
            <ApvContainer2 setPage={setPage} />
          )}
        </>
      )}
    </LayoutBudget>
  );
};

export default ChooseApv;
